import { render, staticRenderFns } from "./InventoryAdd.vue?vue&type=template&id=48f7879e&scoped=true&"
import script from "./InventoryAdd.vue?vue&type=script&lang=js&"
export * from "./InventoryAdd.vue?vue&type=script&lang=js&"
import style0 from "./InventoryAdd.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./InventoryAdd.vue?vue&type=style&index=1&id=48f7879e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48f7879e",
  null
  
)

export default component.exports